import { HeartSmileyIcon } from "Icons/HeartSmiley";

import { css } from "@emotion/react";

import { useRouter } from "next/router";

import { RecruitFeatureList } from "components/recruit/RecruitFeatureList";
import { Divider } from "components/shared/library/Divider";
import { Modal } from "components/shared/library/Modal";
import { Typography } from "components/shared/library/Typography";

import { useAccount } from "contexts/AccountContext";

import { completeRecruitOnboarding } from "apis/onboardings";
import { RecruitPlan } from "apis/recruitingTeam";

import { getRecruitPriceFeatures } from "utils/recruit";
import {
  getCreateNewInterviewUrl,
  getRecruitUrl,
  removeAllQueryParams,
} from "utils/urls";

import { purple, uiGray } from "constants/colors";

const style = {
  planBadge: css({
    background: purple[100],
    borderRadius: 4,
    padding: "4px 8px",
    display: "flex",
    gap: 8,
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
  }),
  plan: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
  features: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
  underline: css({ textDecoration: "underline" }),
};

type PlanSuccessModalProps = {
  plan: RecruitPlan;
};

export const PlanSuccessModal = ({ plan }: PlanSuccessModalProps) => {
  const { accountProfile } = useAccount(true);

  const router = useRouter();

  const trialModalProps = {
    title: "Welcome to Ribbon!",
    description: `You're all set for your ${plan.trialPeriodDays}-day free trial.`,
    isOpen: true,
    confirmLabel: "Create interview now",
    onConfirm: async () => {
      await completeRecruitOnboarding(accountProfile.id);
      router.push(getCreateNewInterviewUrl());
    },
    leftButtonLabel: "Explore on my own",
    leftButtonOnClick: async () => {
      await completeRecruitOnboarding(accountProfile.id);
      router.push(getRecruitUrl());
    },
    onClose: () => undefined,
    showCloseButton: false,
  };

  const handleCloseSuccessModal = () => {
    removeAllQueryParams(router);
  };

  const nonTrialModalProps = {
    title: "Thank you for subscribing!",
    description: "You're all set to start using your new plan.",
    isOpen: true,
    confirmLabel: "Done",
    onClose: handleCloseSuccessModal,
    onConfirm: handleCloseSuccessModal,
    isCancelable: false,
  };

  return (
    <Modal {...(plan.hasPaymentMethod ? nonTrialModalProps : trialModalProps)}>
      <div css={style.plan}>
        <div css={style.planBadge}>
          <HeartSmileyIcon height={16} width={16} />
          {!plan.hasPaymentMethod ? (
            <Typography size="XS">{`${plan.trialPeriodDays} DAY FREE TRIAL`}</Typography>
          ) : (
            <Typography size="XS">Included in {plan.planName}</Typography>
          )}
        </div>
        <Typography size="XL" weight="bold">
          {"You have "}
          <span css={style.underline}>
            {plan.interviewFlowsCap} interview flow
            {plan.interviewFlowsCap === 1 ? "" : "s"}
          </span>
          {" in your plan"}
        </Typography>
        <Typography size="XS" color={uiGray[70]}>
          {"No gimmicks. "}
          {!plan.hasPaymentMethod
            ? `${plan.trialPeriodDays}-day trial, no credit card required.`
            : "Cancel anytime."}
        </Typography>
      </div>
      <Divider />
      <div css={style.features}>
        <Typography size="S" weight="bold">
          What&apos;s included:
        </Typography>
        <RecruitFeatureList features={getRecruitPriceFeatures(plan, null)} />
      </div>
    </Modal>
  );
};
