import { forwardRef, useImperativeHandle, useState } from "react";

import { css } from "@emotion/react";

import { purple, white } from "constants/colors";

const style = {
  rangeInput: (progress: number, height: number, width: number) =>
    css({
      width: width,
      WebkitAppearance: "none",
      backgroundColor: "transparent",
      "::-webkit-slider-thumb": {
        WebkitAppearance: "none",
        position: "relative",
        appearance: "none",
        borderRadius: 20,
        width: height,
        height: height,
        cursor: "pointer",
        background: purple[600],
      },
      "::-webkit-slider-runnable-track": {
        height: height,
        borderRadius: 6,
        backgroundColor: white,
        background: `linear-gradient(to right,${purple[600]} 0px, ${
          purple[600]
        } ${height / 2 + progress * (width - height)}px, white ${
          height / 2 + progress * (width - height)
        }px, white ${width}px)`,
      },
    }),
};

type Props = {
  duration: number;
  seekToSecond: (second: number) => void;
};

const PipTrack = forwardRef(({ seekToSecond, duration }: Props, ref) => {
  const [currentTime, setCurrentTime] = useState(0);

  useImperativeHandle(ref, () => ({
    handleProgress(state: { playedSeconds: number }) {
      setCurrentTime(state.playedSeconds);
    },
  }));

  return (
    <input
      type="range"
      data-testid="pip-track"
      min="0"
      max={duration}
      step={"0.01"}
      value={currentTime}
      onChange={(e) => seekToSecond(parseFloat(e.target.value))}
      css={style.rangeInput(currentTime / duration, 8, 224)}
    />
  );
});

PipTrack.displayName = "PipTrack";

export { PipTrack };
