import { SuperThumbsDown2Icon } from "Icons/SuperThumbsDown2";
import { SuperThumbsUp2Icon } from "Icons/SuperThumbsUp2";
import { ThumbsDownIcon } from "Icons/ThumbsDown";
import { ThumbsUpIcon } from "Icons/ThumbsUp";
import { IconType } from "Icons/types";

import { HireVote } from "apis/recruit";
import { RecruitPlanName } from "apis/recruitingTeam";

export const NUM_OF_FREE_RECRUIT_RESPONSES = 50;
export const voices = [
  {
    id: "11labs-Kate",
    name: "Kate",
    recordingUrl: "/images/Kate.wav",
  },
  {
    id: "openai-Onyx",
    name: "Daniel",
    recordingUrl: "/images/Onyx.wav",
  },
  {
    id: "openai-Shimmer",
    name: "Maria",
    recordingUrl: "/images/Shimmer.wav",
  },
  {
    id: "11labs-Anthony",
    name: "Anthony",
    recordingUrl: "/images/Anthony.wav",
  },
  {
    id: "11labs-Anna",
    name: "Anna",
    recordingUrl: "/images/Anna.wav",
  },
  {
    id: "11labs-Amy",
    name: "Amy",
    recordingUrl: "/images/Amy.wav",
  },
];
export const defaultVoice = "11labs-Kate";
export const languages = [
  {
    id: "en-US",
    label: "English (United States)",
  },
  {
    id: "de-DE",
    label: "German (Germany)",
  },
  {
    id: "es-419",
    label: "Spanish (Latin America)",
  },
  {
    id: "hi-IN",
    label: "Hindi (India)",
  },
  {
    id: "ja-JP",
    label: "Japanese (Japan)",
  },
  {
    id: "pt-BR",
    label: "Portuguese (Brazil)",
  },
  {
    id: "fr-FR",
    label: "French (France)",
  },
];
export const defaultLanguage = "en-US";

type SelectablePlan = Exclude<RecruitPlanName, "Enterprise">;
export const nextUpgradedPlanName: Record<RecruitPlanName, SelectablePlan> = {
  Starter: "Growth",
  Growth: "Business",
  Business: "Business", // user should not be able to upgrade from Business
  Enterprise: "Business", // user should not be able to upgrade from Enterprise
};

export const daysAfterWhichFlowIsPublishable = 14;

export const hireVoteFormat: Record<HireVote, string> = {
  hire: "Hire",
  no_hire: "No hire",
  strong_hire: "Strong hire",
  strong_no_hire: "Strong no hire",
};

export const hireVoteIcon: Record<HireVote, IconType> = {
  hire: ThumbsUpIcon,
  no_hire: ThumbsDownIcon,
  strong_hire: SuperThumbsUp2Icon,
  strong_no_hire: SuperThumbsDown2Icon,
};


export const botEmailAddress = "notetaker@ribbon.ai"