import { useEffect, useState } from "react";

export const isRibbonExtensionInstalled = async () => {
  if (typeof chrome !== "undefined" && chrome.runtime?.sendMessage) {
    return new Promise((resolve) => {
      chrome.runtime.sendMessage(
        "djpciihncdncnahnahjgmjpjcioakgan",
        { type: "isInstalled" },
        (response) => {
          if (!response.success) {
            return resolve(false);
          }
          return resolve(true);
        }
      );
    });
  }

  return false;
};

type IsInstalledLoaded = { isInstalled: boolean; isLoaded: true };
type IsInstalledLoading = { isInstalled: undefined; isLoaded: false };
export const useIsCXInstalled = (): IsInstalledLoaded | IsInstalledLoading => {
  const [isInstalled, setIsInstalled] = useState<boolean>();

  useEffect(() => {
    isRibbonExtensionInstalled().then((isInstalled) => {
      setIsInstalled(!!isInstalled);
    });
  }, []);

  if (typeof isInstalled === "undefined") {
    return { isInstalled: undefined, isLoaded: false };
  } else {
    return { isInstalled, isLoaded: true };
  }
};

export const passAuthTokensToExtension = ({
  refreshToken,
  accessToken,
}: {
  refreshToken?: string;
  accessToken?: string;
}) => {
  if (typeof chrome !== "undefined" && chrome.runtime?.sendMessage) {
    chrome.runtime.sendMessage(
      "djpciihncdncnahnahjgmjpjcioakgan",
      { refreshToken, accessToken, type: "auth" },
      (response) => {
        if (!response.success) {
          return response;
        }
      }
    );
  }
};
export const passAuthTokensToDesktop = ({
  refreshToken,
  accessToken,
}: {
  refreshToken?: string;
  accessToken?: string;
}) => {
  if (!refreshToken || !accessToken) return;
  if (typeof window !== "undefined") {
    // Pass tokens to the Electron desktop app via a custom URL scheme
    const redirectToRemark =
      new URL(window.location.href).searchParams.get("redirect-to-remark") ===
      "true";
    if (!redirectToRemark) return;
    const callbackUrl = `ribbonremark://auth-callback?access_token=${accessToken}&refresh_token=${refreshToken}`;
    // Redirect to the custom URL, which will be handled by the Electron app
    window.location.href = callbackUrl;
  }
};
