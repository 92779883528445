import { ModeratTrialFontModule } from "font/ModeratTrial";

import type { ReactElement, ReactNode } from "react";
import { useEffect } from "react";
import { Provider } from "react-redux";

import { Global, css } from "@emotion/react";

import * as Sentry from "@sentry/nextjs";
import { trackEvent } from "interfaces/tracker";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import NextNProgress from "nextjs-progressbar";
import store from "store/store";

import { Head } from "components/shared/layout/Head";
import { OpenGraph } from "components/shared/layout/OpenGraph";
import { SignupBanner } from "components/shared/layout/SignupBanner";
import { SupportButton } from "components/shared/layout/SupportButton";
import { GoogleAdsTrackingTag } from "components/shared/library/GoogleAdsTrackingTag";
import { MetaPixelTrackingTag } from "components/shared/library/MetaPixelTrackingTag";

import { AccountProvider } from "contexts/AccountContext";
import { PictureInPictureProvider } from "contexts/PictureInPictureContext";
import { PlanGatingProvider } from "contexts/PlanGatingContext";
import { AlertProvider } from "contexts/alertContext";
import { AuthProvider } from "contexts/authContext";

import {
  getExternalPath,
  getHomeUrl,
  getRecruitUrl,
  isRecruit,
} from "utils/urls";

import { purple } from "constants/colors";

import InternalServerError from "./500";

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  const router = useRouter();

  // tracking
  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      // track the event - here a pageview
      trackEvent("pageview", { url });
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head />
      <GoogleAdsTrackingTag />
      <MetaPixelTrackingTag />
      <NextNProgress color={purple[600]} options={{ showSpinner: false }} />
      <OpenGraph
        title={
          isRecruit(router.asPath)
            ? "Ribbon - Recruit top talent"
            : "Ribbon - Land your dream job"
        }
        description={
          isRecruit(router.asPath)
            ? "Recruit top talent with Ribbon. Get started for free and interview top candidates 24/7."
            : "Join 200k+ others landing jobs at top companies. Get started for free with our AI-powered Career Kit."
        }
        url={getExternalPath(
          isRecruit(router.asPath) ? getRecruitUrl() : getHomeUrl()
        )}
        imageUrl={
          isRecruit(router.asPath)
            ? "https://ribbon-public-assets.s3.amazonaws.com/ribbon-recruiters-og.png"
            : undefined // we use the default image
        }
      />

      <Sentry.ErrorBoundary fallback={<InternalServerError />}>
        <Provider store={store}>
          <AlertProvider>
            <PictureInPictureProvider>
              <Global
                styles={css`
                  :root {
                    --Moderat-Trial-font: ${ModeratTrialFontModule.style
                      .fontFamily};
                    accent-color: ${purple[600]};
                  }
                  * {
                    font-family: ${ModeratTrialFontModule.style.fontFamily};
                    outline-color: ${purple[600]};
                  }
                  body {
                    margin: 0;
                    overflow: hidden;
                  }
                  .ant-picker-panel-container {
                    .ant-picker-presets {
                      min-height: 25rem !important;
                    }
                    /* code below makes component DateRangeInput which uses DatePicker from "antd" usable on mobile */
                    @media (max-width: 800px) {
                      overflow: scroll !important;
                      height: 400px;
                      .ant-picker-panel-layout {
                        flex-direction: column !important;

                        .ant-picker-presets {
                          max-width: 100% !important;
                          min-height: 10rem !important;
                        }

                        .ant-picker-panels,
                        .ant-picker-datetime-panel {
                          flex-direction: column !important;
                        }
                      }
                    }
                  }
                `}
              />
              <AuthProvider>
                <AccountProvider>
                  <PlanGatingProvider>
                    {getLayout(<Component {...pageProps} />)}
                    <SignupBanner />
                    <SupportButton />
                  </PlanGatingProvider>
                </AccountProvider>
              </AuthProvider>
            </PictureInPictureProvider>
          </AlertProvider>
        </Provider>
      </Sentry.ErrorBoundary>
    </>
  );
}
