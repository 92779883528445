import { SkipForwardIcon } from "Icons/SkipForward";

import { forwardRef, useImperativeHandle, useState } from "react";

import { Button } from "components/shared/library/Button";

import { Transcript } from "apis/recruit";

import { useSegmentSkipNav } from "utils/recruit";

type Props = {
  transcriptWithTimeStamp?: Transcript;
  skipFwdButtonRef: React.RefObject<HTMLButtonElement>;
  seekToSecond: (second: number) => void;
};

const SkipForwardButton = forwardRef(
  ({ transcriptWithTimeStamp, skipFwdButtonRef, seekToSecond }: Props, ref) => {
    const [currentTime, setCurrentTime] = useState(0);
    const { nextSegment } = useSegmentSkipNav(
      currentTime,
      transcriptWithTimeStamp
    );

    useImperativeHandle(ref, () => ({
      handleProgress(state: { playedSeconds: number }) {
        setCurrentTime(state.playedSeconds);
      },
    }));

    return (
      <Button
        buttonRef={skipFwdButtonRef}
        onClick={() => {
          if (nextSegment) seekToSecond(nextSegment);
        }}
        IconLeft={SkipForwardIcon}
        color="gray"
        size="small"
        background="ghost"
        ariaLabel={"Next section"}
        disabled={!nextSegment}
      />
    );
  }
);

SkipForwardButton.displayName = "SkipForwardButton";

export { SkipForwardButton };
