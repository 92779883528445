import { useEffect, useState } from "react";
import ReactPlayer from "react-player";

import { css } from "@emotion/react";

import { DndContext, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";

import { Portal } from "components/shared/library/Portal";

import { Transcript } from "apis/recruit";

import {
  PictureInPicturePlayer,
  Props as PictureInPicturePlayerProps,
} from "./PictureInPicturePlayer";

const style = {
  background: css({
    position: "absolute",
  }),
};

type Props = {
  isPipMode: boolean;
  pipPlayerRef: React.RefObject<ReactPlayer>;
  url?: string;
  playing: boolean;
  playbackRate: number;
  togglePlay: () => void;
  skipBackButtonRef: React.RefObject<HTMLButtonElement>;
  skipFwdButtonRef: React.RefObject<HTMLButtonElement>;
  transcriptWithTimeStamp?: Transcript;
  seekToSecond: (second: number) => void;
};

export const PipOverlay = ({
  isPipMode,
  ...pipPlayerProps
}: Props & PictureInPicturePlayerProps) => {
  const [positionFromTop, setPositionFromTop] = useState<number>();
  const [positionFromLeft, setPositionFromLeft] = useState<number>();

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } })
  );
  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);
  if (!domLoaded) return <></>;
  return (
    <Portal>
      <DndContext
        sensors={sensors}
        onDragEnd={(event) => {
          const wrapper = document.getElementById("picture-in-picture-window");
          const wrapperDim = wrapper?.getBoundingClientRect();
          if (!wrapperDim) return;

          const top = wrapperDim.y + event.delta.y;
          const left = wrapperDim.x + event.delta.x;
          const bottom = window.innerHeight - top - wrapperDim.height;
          const right = window.innerWidth - left - wrapperDim.width;

          const sides = { top, left, bottom, right };
          const smallerSide = Object.keys(sides).reduce((a, b) => {
            const sideA = a as keyof typeof sides;
            const sideB = b as keyof typeof sides;
            return sides[sideA] < sides[sideB] ? sideA : sideB;
          });

          if (smallerSide === "top") {
            setPositionFromTop(8);
            setPositionFromLeft(sides.left);
          } else if (smallerSide === "left") {
            setPositionFromTop(sides.top);
            setPositionFromLeft(8);
          } else if (smallerSide === "bottom") {
            setPositionFromTop(window.innerHeight - wrapperDim.height - 8);
            setPositionFromLeft(sides.left);
          } else if (smallerSide === "right") {
            setPositionFromTop(sides.top);
            setPositionFromLeft(window.innerWidth - wrapperDim.width - 8);
          }
        }}
      >
        <div
          css={[
            style.background,
            css({
              display: isPipMode ? "flex" : "none",
              top: positionFromTop ?? 8,
              left: positionFromLeft ?? "unset",
              right: !positionFromLeft ? 8 : "unset",
            }),
          ]}
          id="picture-in-picture-window"
          data-testid="picture-in-picture-window"
        >
          <PictureInPicturePlayer {...pipPlayerProps} />
        </div>
      </DndContext>
    </Portal>
  );
};
