import { IconProps } from "Icons/types";

import { uiGray } from "constants/colors";

export const SuperThumbsUp2Icon = ({
  width = 24,
  height = 24,
  stroke = uiGray[90],
}: IconProps) => (
  <svg
    className="hire_vote"
    data-testid="super-thumbs-up-2-icon"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 8.3V16H3.9C3.1268 16 2.5 15.3732 2.5 14.6V9.7C2.5 8.9268 3.1268 8.3 3.9 8.3H5.54509C5.82172 8.3 6.07241 8.13709 6.18476 7.8843L8.64814 2.34169C8.74049 2.13391 8.94654 2 9.17391 2C10.1272 2 10.9 2.7728 10.9 3.72609V6.2C10.9 6.5866 11.2134 6.9 11.6 6.9"
      stroke={stroke}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 21V12.2M6 13.8V19.4C6 20.2837 6.71634 21 7.6 21H18.341C19.5256 21 20.533 20.1357 20.7131 18.9649L21.5746 13.3649C21.7983 11.9111 20.6735 10.6 19.2025 10.6H16.4C15.9582 10.6 15.6 10.2418 15.6 9.8V6.97267C15.6 5.8832 14.7168 5 13.6273 5C13.3675 5 13.132 5.15304 13.0264 5.3905L10.2112 11.7249C10.0827 12.0138 9.79625 12.2 9.4801 12.2H7.6C6.71634 12.2 6 12.9163 6 13.8Z"
      stroke={stroke}
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_4732_70)">
      <path
        d="M20.2907 1.00733C20.3579 0.871139 20.3915 0.803043 20.4372 0.781287C20.4769 0.762358 20.523 0.762358 20.5627 0.781287C20.6083 0.803043 20.6419 0.871139 20.7092 1.00733L21.3469 2.2994C21.3668 2.33961 21.3767 2.35971 21.3912 2.37532C21.404 2.38914 21.4194 2.40034 21.4366 2.40829C21.4559 2.41728 21.4781 2.42052 21.5224 2.427L22.949 2.63553C23.0993 2.65748 23.1744 2.66846 23.2091 2.70516C23.2394 2.73708 23.2536 2.78095 23.2479 2.82455C23.2412 2.87466 23.1869 2.92762 23.0781 3.03356L22.0462 4.03865C22.014 4.06998 21.9979 4.08565 21.9875 4.10429C21.9784 4.12079 21.9725 4.13892 21.9702 4.15768C21.9676 4.17886 21.9714 4.20098 21.979 4.24524L22.2225 5.6649C22.2482 5.81463 22.261 5.8895 22.2369 5.93392C22.2159 5.97258 22.1786 5.99969 22.1353 6.00771C22.0856 6.01692 22.0184 5.98157 21.8839 5.91086L20.6085 5.24015C20.5688 5.21925 20.5489 5.2088 20.528 5.20469C20.5095 5.20106 20.4904 5.20106 20.4719 5.20469C20.4509 5.2088 20.4311 5.21925 20.3913 5.24015L19.1159 5.91086C18.9815 5.98157 18.9142 6.01692 18.8645 6.00771C18.8213 5.99969 18.784 5.97258 18.763 5.93392C18.7388 5.8895 18.7517 5.81463 18.7774 5.6649L19.0208 4.24524C19.0284 4.20098 19.0322 4.17886 19.0297 4.15768C19.0274 4.13892 19.0215 4.12079 19.0123 4.10429C19.0019 4.08565 18.9858 4.06998 18.9537 4.03865L17.9217 3.03356C17.813 2.92762 17.7586 2.87466 17.752 2.82455C17.7462 2.78095 17.7605 2.73708 17.7907 2.70516C17.8255 2.66846 17.9006 2.65748 18.0508 2.63553L19.4774 2.427C19.5218 2.42052 19.544 2.41728 19.5633 2.40829C19.5804 2.40034 19.5958 2.38914 19.6086 2.37532C19.6231 2.35971 19.6331 2.33961 19.6529 2.2994L20.2907 1.00733Z"
        stroke={"#512DFF"}
        fill={"#512DFF"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4732_70">
        <rect width="7" height="7" fill="white" transform="translate(17)" />
      </clipPath>
    </defs>
  </svg>
);
