import { ChevronDownIcon } from "Icons/ChevronDown";
import { ChevronUpIcon } from "Icons/ChevronUp";

import { useRef } from "react";
import { CSSTransition } from "react-transition-group";

import { SerializedStyles, css } from "@emotion/react";

import {
  SLIDE_TRANSITION_DURATION,
  SLIDE_TRANSITION_TIMING_FUNCTION,
} from "constants/animations";
import { uiGray } from "constants/colors";

import { ButtonWrapper } from "./ButtonWrapper";
import { Typography } from "./Typography";

const style = {
  wrapper: css({
    display: "flex",
    flexDirection: "column",
    gap: 24,
    border: `1px solid ${uiGray[10]}`,
    borderRadius: 8,
    width: "-webkit-fill-available",
    "& .collapsable-section-enter": {
      maxHeight: 0,
    },
    "& .collapsable-section-enter-active": {
      maxHeight: "100vh",
      transition: `max-height ${SLIDE_TRANSITION_DURATION} ${SLIDE_TRANSITION_TIMING_FUNCTION}`,
    },
    "& .collapsable-section-exit": {
      maxHeight: "100vh",
    },
    "& .collapsable-section-exit-active": {
      maxHeight: 0,
      transition: `max-height ${SLIDE_TRANSITION_DURATION} ${SLIDE_TRANSITION_TIMING_FUNCTION}`,
    },
  }),
  header: css({
    padding: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
  }),
  title: css({
    display: "grid",
    gap: 8,
  }),
  childrenDiv: css({
    maxHeight: "100vh",
    borderRadius: 12,
    overflow: "hidden",
    padding: 4,
    margin: -4, // this is to account for potential focus shadows
  }),
  icon: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 24,
    height: 24,
  }),
};

export type Props = {
  children: React.ReactNode;
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  initallyExpanded?: boolean;
  isOpen: boolean;
  onClick: (newValue: boolean) => void;
  customCss?: {
    wrapper?: SerializedStyles;
    children?: SerializedStyles;
    header?: SerializedStyles;
  };
};

export const Drawer = ({
  title,
  children,
  description,
  isOpen,
  onClick,
  customCss,
}: Props) => {
  const nodeRef = useRef(null);
  return (
    <div css={[style.wrapper, customCss?.wrapper]} data-testid="drawer">
      <ButtonWrapper
        customCss={[style.header, customCss?.header]}
        onClick={() => onClick(!isOpen)}
        ariaLabel={isOpen ? "Collapse" : "Expand"}
      >
        {typeof title === "string" ? (
          <div css={style.title}>
            <Typography color={uiGray[100]} weight="medium">
              {title}
            </Typography>
            {description && (
              <Typography color={uiGray[70]} size="XS" tag="p">
                {description}
              </Typography>
            )}
          </div>
        ) : (
          title
        )}
        <div css={style.icon}>
          {isOpen ? (
            <ChevronUpIcon width={12} height={12} stroke={uiGray[70]} />
          ) : (
            <ChevronDownIcon width={12} height={12} stroke={uiGray[70]} />
          )}
        </div>
      </ButtonWrapper>
      <CSSTransition
        nodeRef={nodeRef}
        in={isOpen}
        timeout={200}
        unmountOnExit
        classNames="collapsable-section"
      >
        <div css={[style.childrenDiv, customCss?.children]} ref={nodeRef}>
          {children}
        </div>
      </CSSTransition>
    </div>
  );
};
