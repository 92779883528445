import { SkipBackIcon } from "Icons/SkipBack";

import { forwardRef, useImperativeHandle, useState } from "react";

import { Button } from "components/shared/library/Button";

import { Transcript } from "apis/recruit";

import { useSegmentSkipNav } from "utils/recruit";

type Props = {
  transcriptWithTimeStamp?: Transcript;
  skipBackButtonRef: React.RefObject<HTMLButtonElement>;
  seekToSecond: (second: number) => void;
};

const SkipBackButton = forwardRef(
  (
    { transcriptWithTimeStamp, skipBackButtonRef, seekToSecond }: Props,
    ref
  ) => {
    const [currentTime, setCurrentTime] = useState(0);
    const { prevSegment } = useSegmentSkipNav(
      currentTime,
      transcriptWithTimeStamp
    );

    useImperativeHandle(ref, () => ({
      handleProgress(state: { playedSeconds: number }) {
        setCurrentTime(state.playedSeconds);
      },
    }));

    return (
      <Button
        buttonRef={skipBackButtonRef}
        onClick={() => {
          if (prevSegment) seekToSecond(prevSegment);
        }}
        IconLeft={SkipBackIcon}
        color="gray"
        size="small"
        background="ghost"
        ariaLabel={"Previous section"}
        disabled={!prevSegment}
      />
    );
  }
);

SkipBackButton.displayName = "SkipBackButton";

export { SkipBackButton };
