import { addDays } from "date-fns";
import { mapValues } from "lodash";
import { RetellWebClient } from "retell-client-js-sdk";

import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { logger } from "interfaces/logger";
import { addCounters, selectInterviewsCounters } from "store/interviewsSlice";
import store from "store/store";

import { Candidate } from "components/recruit/candidate/Candidate";
import { FrameShape } from "components/shared/library/Avatar";

import { useRealtimeChannelHealth } from "utils/realtime";

import { daysAfterWhichFlowIsPublishable } from "constants/recruit";

import { fetchAuthWrapper } from "./backend";
import { PipelineMemberStatus } from "./candidatePipelines";
import { RecruitPlanName, useRecruiterTeam } from "./recruitingTeam";
import { supabase } from "./supabaseClient";
import { UserInfo } from "./user";

export type InterviewFlowStatus = "open" | "closed" | "archived";

export type InterviewFlow = {
  id: string;
  roleTitle: string;
  status: InterviewFlowStatus;
  createdAt: string;
  numCandidates: number;
  numNeedsReview: number;
};

export type InterviewFlowDb = {
  id: string;
  role_title: string;
  status: InterviewFlowStatus;
  created_at: string;
  num_candidates: number;
  num_need_reviews: number;
};

type Candidate = Pick<UserInfo, "id" | "firstName" | "lastName" | "email"> &
  Pick<UserInfo, "avatarUrl" | "shape" | "handle">;

export type InterviewCall = {
  id: string;
  recordingUrl: string;
  durationInSec: number;
  filters: { questionId: string; answer: string }[];
  endedAt: string;
  overallScore?: number;
  customScore?: number;
  numOfDocuments: number;
};

export type FlowCandidateAndLatestCall = InterviewCall & {
  addedAt: string;
  pipelineMemberStatus: PipelineMemberStatus;
  candidate: Candidate;
  votes: Record<HireVote, Voter[]>;
  authedUserVote?: HireVote;
};

export type InterviewOverview = {
  active: number;
  total: number;
  totalScreened: number;
};

type InterviewOverviewDb = {
  active: number;
  non_archived_recruitment_count: number;
  total_screened: number;
};

export type FlowCandidateAndLatestCallDb = {
  retell_call_id: string;
  recording_url: string;
  duration_in_s: number;
  filters: { question_id: string; answer: string }[];
  ended_at: string;
  pipeline_member_status: PipelineMemberStatus;
  overall_score?: number;
  custom_score?: number;
  candidate_id: string;
  candidate_first_name: string;
  candidate_last_name: string;
  candidate_photo_url: string;
  candidate_shape: string;
  candidate_handle: string;
  candidate_email: string;
  num_of_documents: number;
  added_at: string;
  votes: Record<HireVote, DbVoter[]>;
  authed_user_vote?: HireVote;
};

export type InterviewBasic = {
  id: string;
  orgName: string;
  roleTitle: string;
  companyLogo?: string;
};

export type InterviewDetails = InterviewBasic & {
  retellAgentId?: string;
  voiceId: string;
  language: string;
  location?: string;
  jobDescription?: string;
  status: InterviewFlowStatus;
  createdAt: string;
  questions: {
    question: string;
    possibleAnswers?: string[];
  }[];
  atsStageId?: string;
  atsJobId?: string;
  candidatesCount: number;
  successfulInterviewCalls: number;
  isRibbonSourcingEnabled: boolean;
  isCallPublishableAfterDays: number;
  retriesLimit: number;
  enableFeedback: boolean;
  hiringManager?: string;
  additionalInfo?: string;
  isVideoEnabled: boolean;
  redirectUrl?: string;
  isRescoringInProgress: boolean;
};

export type InterviewDetailsDb = {
  id: string;
  retell_agent_id?: string;
  retell_voice_id: string;
  language: string;
  org_name: string;
  role_title: string;
  location?: string;
  job_description?: string;
  status: InterviewFlowStatus;
  created_at: string;
  questions: {
    question: string;
    possible_answers: string[];
  }[];
  ats_integration_job_id: string;
  ats_integration_stage_id_on_call_success: string;
  successful_interview_calls: number;
  candidates_count: number;
  is_ribbon_sourcing_enabled: boolean;
  is_call_publishable_after_days: number | null;
  retries_limit: number | null;
  enable_feedback: boolean;
  hiring_manager?: string;
  company_logo?: string;
  additional_info?: string;
  is_video_enabled: boolean;
  redirect_url?: string;
  rescoring_processing_status: "done" | "in-progress";
};

type InterviewFlowQuestionDb = {
  id: string;
  interview_flow_id: string;
  question: string;
  possible_answers: string[];
  created_at: string;
};
export type InterviewFlowQuestion = {
  id: string;
  interviewFlowId: string;
  question: string;
  possibleAnswers: string[];
  createdAt: string;
};

export type Word = {
  word: string;
  start: number;
  end: number;
};

export type Segment = {
  content: string;
  role: "agent" | "user" | string;
  words: Word[];
};

export type Transcript = Segment[];

export type InterviewLanguage =
  | "en-US"
  | "de-DE"
  | "es-419"
  | "hi-IN"
  | "ja-JP"
  | "pt-BR"
  | "fr-FR";

export type HireVote = "strong_hire" | "hire" | "no_hire" | "strong_no_hire";
export type Voter = Pick<UserInfo, "avatarUrl" | "id" | "fullName"> & {
  isAuthedVote: boolean;
};
type DbVoter = {
  id: string;
  avatar_url?: string;
  full_name: string;
  is_authed_vote: boolean;
};
type DbCandidateInterviewDetail = {
  candidate_id: string;
  pipeline_member_status: PipelineMemberStatus;
  candidate_handle: string;
  candidate_first_name: string;
  candidate_last_name: string;
  candidate_photo_url: string;
  candidate_shape: string;
  candidate_email: string;
  call_id: string;
  interview_call_output_id: string;
  transcript: string;
  summary: string;
  background?: string[];
  motivation_and_interest?: string[];
  logistical_considerations?: string[];
  candidate_questions?: string[];
  tools_and_skills?: string[];
  recording_url: string;
  video_playback_url?: string;
  called_at: string;
  ended_at: string;
  filters: {
    question: string;
    question_id: string;
    answer: string;
  }[];
  score_communication?: number;
  score_motivation?: number;
  score_skills?: number;
  score_language_comprehension?: number;
  score_language_fluency_and_pace?: number;
  score_language_grammar_and_structure?: number;
  score_language_vocabulary_and_expression?: number;
  created_at: string;
  transcript_with_timestamp: Transcript;
  wrapped_url?: string;
  follow_up_questions?: string[];
  team_id: string;
  org_name: string;
  interview_language: InterviewLanguage;
  is_shared_by_recruiter: boolean;
  is_user_in_team: boolean;
  is_published_by_candidate: boolean;
  questions?: Question[];
  strong_hire_votes: DbVoter[];
  hire_votes: DbVoter[];
  no_hire_votes: DbVoter[];
  strong_no_hire_votes: DbVoter[];
  authed_user_vote: HireVote;
};

export type Question = {
  id: string;
  score?: number;
  question: string;
  segments: Transcript;
};

export type InterviewDetailOutput = {
  interviewCallOutputId: string;
  transcript: string;
  summary: string;
  background?: string[];
  motivationAndInterest?: string[];
  logisticalConsiderations?: string[];
  candidateQuestions?: string[];
  toolsAndSkills?: string[];
  recordingUrl: string;
  videoPlaybackUrl?: string;
  calledAt: string;
  endedAt: string;

  scoreCommunication?: number;
  scoreMotivation?: number;
  scoreSkills?: number;
  scoreLanguageComprehension?: number;
  scoreLanguageFluencyAndPace?: number;
  scoreLanguageGrammarAndSctructure?: number;
  scoreLanguageVocabularyAndExpression?: number;
  createdAt: string;
  transcriptWithTimeStamp?: Transcript;
  followUpQuestions?: string[];
  teamId: string;
};
export type CandidateInterviewDetail = InterviewDetailOutput & {
  pipelineMemberStatus: PipelineMemberStatus;
  candidate: Candidate;
  callId: string;
  filters: {
    question: string;
    question_id: string;
    answer: string;
  }[];
  wrappedUrl?: string;
  orgName: string;
  interviewLanguage: InterviewLanguage;
  isSharedByRecruiter: boolean;
  isUserInTeam: boolean;
  isPublishedByCandidate: boolean;
  questions?: Question[];
  votes: Record<HireVote, Voter[]>;
  authedUserVote?: HireVote;
};

type InterviewType = "recruitment" | "general";

export type InterviewJobInfo = Pick<
  InterviewDetails,
  | "companyLogo"
  | "jobDescription"
  | "roleTitle"
  | "orgName"
  | "location"
  | "isVideoEnabled"
  | "retriesLimit"
  | "redirectUrl"
> & {
  teamId: string;
  interviewType: InterviewType;
  isCandidateAllowedToPublish: boolean;
};

type DbInterviewSettings = {
  is_ribbon_sourcing_enabled: boolean;
  is_call_publishable_after_days: number | null;
  retries_limit: number | null;
  enable_feedback: boolean;
  redirect_url: string | null;
};
export type InterviewSetting = {
  isSourcingEnabled: boolean;
  isPublishable: boolean;
  isRetryDisabled: boolean;
  isFeedbackEnabled: boolean;
  recruitUrl: string | null;
};

export const getClientSecret = async (): Promise<string | undefined> => {
  const res = await fetchAuthWrapper.get("/be-api/get-client-secret");

  if (res.status !== 200) {
    logger("Error get-client-secret", "error");
  }

  const { body } = await res.json();

  return body.client_secret;
};

export const getInterviewCallId = async (
  interviewFlowId: string
): Promise<
  | {
      error: undefined;
      data: {
        callId: string;
        phoneNumber?: string;
        accessToken: string;
        mediaUploadUrl: string;
      };
    }
  | {
      error: string;
      data: undefined;
    }
> => {
  const res = await fetchAuthWrapper.post("/be-api/get-interview-call-id", {
    interviewFlowId,
  });

  if (res.status === 504) {
    // call it again if we hit a timeout
    return getInterviewCallId(interviewFlowId);
  }

  if (res.status !== 200) {
    logger("Error get-interview-call-id", "error");
    return {
      error: "Something unexpected happened",
      data: undefined,
    };
  }

  const { body } = await res.json();

  return {
    error: undefined,
    data: {
      callId: body.retell_call_id,
      phoneNumber: body.phone_number,
      accessToken: body.access_token,
      mediaUploadUrl: body.media_upload_url,
    },
  };
};

export const findInterviewJobInfo = async (
  interviewFlowId: string
): Promise<InterviewJobInfo | undefined> => {
  const { data, error } = await supabase.rpc("get_interview_job_info", {
    p_interview_flow_id: interviewFlowId,
  });
  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }
  if (!data.length) {
    logger(`No interview found with id [${interviewFlowId}]`, "error");
    return undefined;
  }

  return {
    companyLogo: data[0].company_logo,
    jobDescription: data[0].job_description,
    roleTitle: data[0].role_title,
    orgName: data[0].org_name,
    location: data[0].location,
    isVideoEnabled: data[0].is_video_enabled,
    retriesLimit: data[0].retries_limit ?? Infinity,
    teamId: data[0].team_id,
    interviewType: data[0].interview_type,
    redirectUrl: data[0].redirect_url,
    isCandidateAllowedToPublish: data[0].is_candidate_allowed_to_publish,
  };
};

export const getInterviewPhoneNumber = async (
  interviewFlowId: string
): Promise<{ phoneNumber?: string; error?: string } | undefined> => {
  const res = await fetchAuthWrapper.post(
    "/be-api/get-interview-phone-number",
    {
      interviewFlowId,
    }
  );

  if (res.status !== 200) {
    logger("Error get-interview-phone-number", "error");
    return { error: "Something unexpected happened" };
  }

  const { body } = await res.json();

  return { phoneNumber: body.phone_number };
};

export const shareFilesToInterview = async ({
  userId,
  interviewFlowId,
  selectedFiles,
}: {
  userId: string;
  interviewFlowId: string;
  selectedFiles: string[];
}) => {
  // delete all existing
  const { error: deleteError } = await supabase
    .from("interview_flow_user_document")
    .delete()
    .eq("interview_flow_id", interviewFlowId)
    .eq("candidate_id", userId);

  if (deleteError) {
    throw new Error(deleteError.message);
  }
  // add the newly selected
  const { error } = await supabase.from("interview_flow_user_document").insert(
    selectedFiles.map((filename) => ({
      interview_flow_id: interviewFlowId,
      candidate_id: userId,
      filename,
    }))
  );

  if (error) {
    throw new Error(error.message);
  }
};

// Initialize the SDK
const retellClient = new RetellWebClient();
export const useCallRecruiAIAgent = () => {
  const [status, setStatus] = useState<"not-started" | "ongoing" | "ended">(
    "not-started"
  );
  const [isAgentTalking, setIsAgentTalking] = useState(false);
  const hasStartCallBeenCalled = useRef(false);

  useEffect(() => {
    retellClient.on("call_started", () => setStatus("ongoing"));
    retellClient.on("call_ended", () => setStatus("ended"));
    retellClient.on("agent_start_talking", () => setIsAgentTalking(true));
    retellClient.on("agent_stop_talking", () => setIsAgentTalking(false));
    retellClient.on("error", (error) => {
      logger(`Recruit AI error: ${error}`, "error");
    });
  }, []);

  const startCall = useCallback(
    (accessToken: string, stream?: MediaStream, sinkId?: string) => {
      if (hasStartCallBeenCalled.current) {
        // Starting a call twice will lead to the call being cut off early by the server
        logger("Call already started", "error");
        return;
      }
      hasStartCallBeenCalled.current = true; // Mark as started

      retellClient.startCall({
        accessToken: accessToken,
        sampleRate: 24000,
        captureDeviceId: stream?.getAudioTracks()[0]?.getSettings().deviceId,
        playbackDeviceId: sinkId === "default" ? undefined : sinkId,
      });
    },
    []
  );

  const endCall = useCallback(() => retellClient.stopCall(), []);

  return {
    startCall,
    endCall,
    callStatus: status,
    isAgentTalking,
    mute: () => retellClient.mute(),
    unmute: () => retellClient.unmute(),
  };
};

export const createInterview = async ({
  orgName,
  roleTitle,
  questions,
  jobDescription,
  location,
  atsJobId,
  atsStageId,
  voiceId,
  language,
  isRibbonSourcingEnabled,
  isPublishable,
  retriesLimit,
  enableFeedback,
  hiringManager,
  additionalInfo,
  companyLogo,
  isVideoEnabled,
}: {
  orgName: string;
  roleTitle: string;
  questions: string[];
  jobDescription?: string;
  location?: string;
  atsJobId?: string;
  atsStageId?: string;
  voiceId: string;
  language: string;
  isRibbonSourcingEnabled?: boolean;
  isPublishable?: boolean;
  retriesLimit?: number;
  enableFeedback?: boolean;
  hiringManager?: string;
  additionalInfo?: string;
  companyLogo?: string;
  isVideoEnabled: boolean;
}): Promise<{ interviewFlowId?: string; error?: string }> => {
  const res = await fetchAuthWrapper.post("/be-api/create-interview-flow", {
    orgName,
    roleTitle,
    location,
    jobDescription,
    questions,
    atsJobId,
    atsStageId,
    voiceId,
    language,
    isRibbonSourcingEnabled,
    isPublishable,
    retriesLimit: retriesLimit === Infinity ? null : retriesLimit,
    enableFeedback,
    hiringManager,
    additionalInfo,
    companyLogo,
    isVideoEnabled,
  });

  if (res.status !== 200) {
    logger("Error creating recruit AI interview", "error");
    return { error: "Something unexpected happened" };
  }

  const { body } = await res.json();

  return { interviewFlowId: body.interview_flow_id };
};

export const updateInterview = async ({
  interviewFlowId,
  orgName,
  roleTitle,
  questions,
  jobDescription,
  location,
  atsJobId,
  atsStageId,
  voiceId,
  language,
  hiringManager,
  additionalInfo,
  companyLogo,
  isVideoEnabled,
}: {
  interviewFlowId: string;
  orgName: string;
  roleTitle: string;
  questions: string[];
  jobDescription?: string;
  location?: string;
  atsJobId?: string;
  atsStageId?: string;
  voiceId: string;
  language: string;
  hiringManager?: string;
  additionalInfo?: string;
  companyLogo?: string;
  isVideoEnabled: boolean;
}): Promise<{ interviewFlowId?: string; error?: string }> => {
  const res = await fetchAuthWrapper.post("/be-api/update-interview-flow", {
    interviewFlowId,
    orgName,
    roleTitle,
    location,
    jobDescription,
    questions,
    atsJobId,
    atsStageId,
    voiceId,
    language,
    hiringManager,
    additionalInfo,
    companyLogo,
    isVideoEnabled,
  });

  if (res.status !== 200) {
    logger("Error updating recruit AI interview", "error");
    return { error: "Something unexpected happened" };
  }

  const { body } = await res.json();

  return { interviewFlowId: body.interview_flow_id };
};

export const updateInterviewSettings = async ({
  interviewFlowId,
  ...settings
}: {
  interviewFlowId: string;
} & Partial<InterviewSetting>) => {
  const changes = Object.entries(settings)
    .filter(([_k, v]) => typeof v !== "undefined")
    .reduce((acc, [k, v]) => {
      if (k === "isSourcingEnabled") {
        acc.is_ribbon_sourcing_enabled = v as boolean;
      }
      if (k === "isPublishable") {
        acc.is_call_publishable_after_days = v
          ? daysAfterWhichFlowIsPublishable
          : null;
      }
      if (k === "isRetryDisabled") {
        acc.retries_limit = v ? 0 : null;
      }
      if (k === "isFeedbackEnabled") {
        acc.enable_feedback = v as boolean;
      }
      if (k === "recruitUrl") {
        acc.redirect_url = v as string | null;
      }
      return acc;
    }, {} as Partial<DbInterviewSettings>);

  const { error } = await supabase
    .from("interview_flow")
    .update(changes)
    .eq("id", interviewFlowId);

  if (error) {
    logger(error.message, "error");
    return {
      error: "Something went wrong when updating the interview flow settings",
    };
  }
  return {};
};

const formatDbInterview = (data: InterviewFlowDb[]): InterviewFlow[] => {
  return data.map((interview: InterviewFlowDb) => ({
    id: interview.id,
    roleTitle: interview.role_title,
    status: interview.status,
    createdAt: interview.created_at,
    numCandidates: interview.num_candidates,
    numNeedsReview: interview.num_need_reviews,
  }));
};

const getYourInterviews = async (
  pageOffset: number,
  pageSize: number
): Promise<InterviewFlow[]> => {
  const { data, error } = await supabase.rpc("get_your_interviews", {
    page_size: pageSize,
    page_offset: pageOffset,
  });

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return formatDbInterview(data);
};

export const useYourInterviews = () => {
  const [loadingMore, setLoadingMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [isFinalPage, setIsFinalPage] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [interviews, setInterviews] = useState<InterviewFlow[]>([]);
  const firstPageSize = 3; // we fetch 3 on the first page, then all the rest on the next

  const loadMoreInterviews = useCallback(
    async (pageSize: number, pageOffset: number) => {
      setLoadingMore(false);
      const data = await getYourInterviews(pageOffset, pageSize);
      setPageNumber((state) => state + 1);
      setInterviews((prevState) => [...prevState, ...data]);
      setLoadingMore(true);
      if (data.length < pageSize) {
        setIsFinalPage(true);
        return;
      }
      setIsFinalPage(false);
    },
    []
  );

  const reload = useCallback(async () => {
    setIsLoaded(false);
    setPageNumber(0);
    setInterviews([]);
  }, []);

  useEffect(() => {
    if (pageNumber > 0) return;
    const loadMore = async () => {
      // we only load 3 interviews on first load
      await loadMoreInterviews(firstPageSize, 0);
      setIsLoaded(true);
    };
    loadMore();
  }, [loadMoreInterviews, pageNumber]);

  return {
    interviews,
    isFinalPage,
    loadAllInterviews: () => loadMoreInterviews(Infinity, firstPageSize),
    loadingMore,
    isLoaded,
    reload,
  };
};

const formatInterviewCallDb = (
  data: FlowCandidateAndLatestCallDb[]
): FlowCandidateAndLatestCall[] => {
  return data.map((interviewCall) => ({
    id: interviewCall.retell_call_id,
    recordingUrl: interviewCall.recording_url,
    durationInSec: interviewCall.duration_in_s,
    filters: interviewCall.filters?.map((filter) => ({
      questionId: filter.question_id,
      answer: filter.answer,
    })),
    endedAt: interviewCall.ended_at,
    addedAt: interviewCall.added_at,
    pipelineMemberStatus: interviewCall.pipeline_member_status,
    overallScore: interviewCall.overall_score,
    customScore: interviewCall.custom_score,
    candidate: {
      id: interviewCall.candidate_id,
      firstName: interviewCall.candidate_first_name,
      lastName: interviewCall.candidate_last_name,
      avatarUrl: interviewCall.candidate_photo_url,
      shape: interviewCall.candidate_shape as FrameShape,
      handle: interviewCall.candidate_handle,
      email: interviewCall.candidate_email,
    },
    numOfDocuments: interviewCall.num_of_documents,
    votes: mapValues(interviewCall.votes, (dbVotes) =>
      dbVotes.map((dbVote) => ({
        id: dbVote.id,
        fullName: dbVote.full_name,
        avatarUrl: dbVote.avatar_url,
        isAuthedVote: dbVote.is_authed_vote,
      }))
    ),
    authedUserVote: interviewCall.authed_user_vote,
  }));
};

export const getCandidateInterviewsOverviews = async (
  interviewFlowId: string
): Promise<FlowCandidateAndLatestCall[]> => {
  const { data, error } = await supabase.rpc(
    "get_candidate_interviews_overviews",
    {
      p_interview_flow_id: interviewFlowId,
    }
  );

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return formatInterviewCallDb(data);
};

export type LatestCandidate = {
  candidate: Pick<
    UserInfo,
    "id" | "avatarUrl" | "email" | "firstName" | "lastName"
  > & {
    location?: string;
  };
  roleTitle: string;
  interviewFlowId: string;
};

type DbLatestCandidate = {
  candidate_avatar_url: string;
  candidate_id: string;
  candidate_email: string;
  candidate_first_name?: string;
  candidate_last_name?: string;
  candidate_location?: string;
  role_title: string;
  interview_flow_id: string;
};

// we currently do not know / understand why getLatestCandidates is being picked on by ts-prune even though it is used
// this flag escapes it, but we should avoid to use this flag anywhere else
// ts-prune-ignore-next
export const getLatestCandidates = async (
  limit: number
): Promise<LatestCandidate[]> => {
  const { data, error } = await supabase.rpc("get_latest_candidates", {
    p_limit: limit,
  });

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return data.map((d: DbLatestCandidate) => ({
    candidate: {
      avatarUrl: d.candidate_avatar_url,
      id: d.candidate_id,
      email: d.candidate_email,
      firstName: d.candidate_first_name,
      lastName: d.candidate_last_name,
      location: d.candidate_location,
    },
    roleTitle: d.role_title,
    interviewFlowId: d.interview_flow_id,
  }));
};

type CallLoaded = { calls: FlowCandidateAndLatestCall[]; isLoaded: true };
type CallLoading = { calls: undefined; isLoaded: false };
export const useCandidateInterviewsOverviewsRealtime = (
  interviewFlowId: string
): CallLoaded | CallLoading => {
  const [calls, setCalls] = useState<FlowCandidateAndLatestCall[]>();

  const interviewCallChannelName = "db-interview-call";
  const isChannelHealthy = useRealtimeChannelHealth(
    interviewCallChannelName,
    !!interviewFlowId
  );

  useEffect(() => {
    // only sub if we have no interview_calls
    if (!interviewFlowId || typeof calls != "undefined") return;

    const channel = supabase.channel(interviewCallChannelName);

    channel.on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "retell_call_interview",
        filter: `interview_flow_id=eq.${interviewFlowId}`,
      },
      async () =>
        setCalls(await getCandidateInterviewsOverviews(interviewFlowId))
    );

    channel.on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "retell_call_interview",
        filter: `interview_flow_id=eq.${interviewFlowId}`,
      },
      async () =>
        setCalls(await getCandidateInterviewsOverviews(interviewFlowId))
    );

    channel.on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "candidate_pipeline_member",
        filter: `interview_flow_id=eq.${interviewFlowId}`,
      },
      async () =>
        setCalls(await getCandidateInterviewsOverviews(interviewFlowId))
    );

    channel.on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "candidate_pipeline_member",
        filter: `interview_flow_id=eq.${interviewFlowId}`,
      },
      async () =>
        setCalls(await getCandidateInterviewsOverviews(interviewFlowId))
    );

    channel.subscribe(async (status) => {
      if (status === "SUBSCRIBED") {
        setCalls(await getCandidateInterviewsOverviews(interviewFlowId));
      }
    });
  }, [interviewFlowId, isChannelHealthy, calls]);

  // we check that the value is a undefined, since [] is falsy in javascript
  if (typeof calls !== "undefined") {
    return { calls, isLoaded: true };
  } else {
    return { calls: undefined, isLoaded: false };
  }
};

const formatDbInterviewDetails = (
  data: InterviewDetailsDb
): InterviewDetails => {
  return {
    id: data.id,
    retellAgentId: data.retell_agent_id,
    voiceId: data.retell_voice_id,
    language: data.language,
    orgName: data.org_name,
    roleTitle: data.role_title,
    location: data.location,
    jobDescription: data.job_description,
    status: data.status,
    createdAt: data.created_at,
    questions: data.questions.map((q) => ({
      question: q.question,
      possibleAnswers: q.possible_answers,
    })),
    atsJobId: data.ats_integration_job_id,
    atsStageId: data.ats_integration_stage_id_on_call_success,
    successfulInterviewCalls: data.successful_interview_calls,
    candidatesCount: data.candidates_count,
    isRibbonSourcingEnabled: data.is_ribbon_sourcing_enabled,
    isCallPublishableAfterDays: data.is_call_publishable_after_days ?? Infinity,
    retriesLimit: data.retries_limit ?? Infinity,
    enableFeedback: data.enable_feedback,
    hiringManager: data.hiring_manager,
    companyLogo: data.company_logo,
    additionalInfo: data.additional_info,
    isVideoEnabled: data.is_video_enabled,
    redirectUrl: data.redirect_url,
    isRescoringInProgress: data.rescoring_processing_status === "in-progress",
  };
};

export const getInterviewDetails = async (
  interviewFlowId: string
): Promise<{
  error?: string;
  interviewDetail?: InterviewDetails;
}> => {
  const { data, error } = await supabase.rpc("get_interview_details", {
    interview_id: interviewFlowId,
  });

  if (error || !data.length) {
    const message = error?.message || "No data found";
    logger(message, "error");
    return { error: message };
  }

  return { interviewDetail: formatDbInterviewDetails(data[0]) };
};

type InterviewDetailLoaded = {
  interviewDetail?: InterviewDetails;
  isLoaded: true;
};
type InterviewDetailLoading = {
  interviewDetail: undefined;
  isLoaded: false;
};
export const useInterviewDetailsRealtime = (
  interviewFlowId: string
): InterviewDetailLoaded | InterviewDetailLoading => {
  const [interviewDetail, setInterviewDetail] = useState<InterviewDetails>();
  const [isLoaded, setIsLoaded] = useState(false);

  const interviewFlowDetailChannelName = "db-interview-flow-details";
  const isChannelHealthy = useRealtimeChannelHealth(
    interviewFlowDetailChannelName
  );

  const getDetails = useCallback(async () => {
    const { interviewDetail: data } = await getInterviewDetails(
      interviewFlowId
    );
    if (data) {
      setInterviewDetail(data);
    }
    setIsLoaded(true);
  }, [interviewFlowId]);

  useEffect(() => {
    // only sub if not already
    if (isLoaded && isChannelHealthy) return;
    const channel = supabase.channel(interviewFlowDetailChannelName);

    channel.on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "interview_flow",
        filter: `id=eq.${interviewFlowId}`,
      },
      getDetails
    );

    channel.subscribe(async (status) => {
      if (status === "SUBSCRIBED") {
        getDetails();
      }
    });
  }, [interviewFlowId, isChannelHealthy, getDetails, isLoaded]);

  if (isLoaded) {
    return { interviewDetail, isLoaded: true };
  } else {
    return { interviewDetail: undefined, isLoaded: false };
  }
};

const formatDbCandidateInterviewDetail = (
  data: DbCandidateInterviewDetail[]
): CandidateInterviewDetail[] => {
  return data.map((candidateInterviewDetail) => ({
    pipelineMemberStatus: candidateInterviewDetail.pipeline_member_status,
    candidate: {
      id: candidateInterviewDetail.candidate_id,
      firstName: candidateInterviewDetail.candidate_first_name,
      lastName: candidateInterviewDetail.candidate_last_name,
      avatarUrl: candidateInterviewDetail.candidate_photo_url,
      shape: candidateInterviewDetail.candidate_shape as FrameShape,
      handle: candidateInterviewDetail.candidate_handle,
      email: candidateInterviewDetail.candidate_email,
    },
    callId: candidateInterviewDetail.call_id,
    interviewCallOutputId: candidateInterviewDetail.interview_call_output_id,
    transcript: candidateInterviewDetail.transcript,
    summary: candidateInterviewDetail.summary,
    background: candidateInterviewDetail.background,
    motivationAndInterest: candidateInterviewDetail.motivation_and_interest,
    logisticalConsiderations:
      candidateInterviewDetail.logistical_considerations,
    candidateQuestions: candidateInterviewDetail.candidate_questions,
    toolsAndSkills: candidateInterviewDetail.tools_and_skills,
    recordingUrl: candidateInterviewDetail.recording_url,
    videoPlaybackUrl: candidateInterviewDetail.video_playback_url,
    calledAt: candidateInterviewDetail.called_at,
    endedAt: candidateInterviewDetail.ended_at,
    filters: candidateInterviewDetail.filters,
    scoreCommunication: candidateInterviewDetail.score_communication,
    scoreMotivation: candidateInterviewDetail.score_motivation,
    scoreSkills: candidateInterviewDetail.score_skills,
    scoreLanguageComprehension:
      candidateInterviewDetail.score_language_comprehension,
    scoreLanguageFluencyAndPace:
      candidateInterviewDetail.score_language_fluency_and_pace,
    scoreLanguageGrammarAndSctructure:
      candidateInterviewDetail.score_language_grammar_and_structure,
    scoreLanguageVocabularyAndExpression:
      candidateInterviewDetail.score_language_vocabulary_and_expression,
    createdAt: candidateInterviewDetail.created_at,
    transcriptWithTimeStamp:
      candidateInterviewDetail.transcript_with_timestamp?.filter(
        (segment) => segment.words.length > 0
      ),
    wrappedUrl: candidateInterviewDetail.wrapped_url,
    followUpQuestions: candidateInterviewDetail.follow_up_questions,
    teamId: candidateInterviewDetail.team_id,
    orgName: candidateInterviewDetail.org_name,
    interviewLanguage: candidateInterviewDetail.interview_language,
    isSharedByRecruiter: candidateInterviewDetail.is_shared_by_recruiter,
    isUserInTeam: candidateInterviewDetail.is_user_in_team,
    isPublishedByCandidate: candidateInterviewDetail.is_published_by_candidate,
    questions: candidateInterviewDetail.questions,
    votes: {
      strong_hire: candidateInterviewDetail.strong_hire_votes.map((dbVote) => ({
        id: dbVote.id,
        fullName: dbVote.full_name,
        avatarUrl: dbVote.avatar_url,
        isAuthedVote: dbVote.is_authed_vote,
      })),
      hire: candidateInterviewDetail.hire_votes.map((dbVote) => ({
        id: dbVote.id,
        fullName: dbVote.full_name,
        avatarUrl: dbVote.avatar_url,
        isAuthedVote: dbVote.is_authed_vote,
      })),
      no_hire: candidateInterviewDetail.no_hire_votes.map((dbVote) => ({
        id: dbVote.id,
        fullName: dbVote.full_name,
        avatarUrl: dbVote.avatar_url,
        isAuthedVote: dbVote.is_authed_vote,
      })),
      strong_no_hire: candidateInterviewDetail.strong_no_hire_votes.map(
        (dbVote) => ({
          id: dbVote.id,
          fullName: dbVote.full_name,
          avatarUrl: dbVote.avatar_url,
          isAuthedVote: dbVote.is_authed_vote,
        })
      ),
    },
    authedUserVote: candidateInterviewDetail.authed_user_vote,
  }));
};

// NOTE: this is plural but right now only returns one set of details
// in the near future we will support multiple calls per candidate<->interview
export const getCandidateInterviewDetails = async ({
  interviewFlowId,
  candidateId,
}: {
  interviewFlowId: string;
  candidateId: string;
}): Promise<{
  error?: string;
  candidateInterviewDetail?: CandidateInterviewDetail;
}> => {
  const { data, error } = await supabase.rpc(
    "get_candidate_interview_details",
    {
      p_interview_flow_id: interviewFlowId,
      p_candidate_id: candidateId,
    }
  );

  if (error || !data.length) {
    const message = error?.message || "No data found";
    logger(message, "error");
    return { error: message };
  }

  return {
    candidateInterviewDetail: formatDbCandidateInterviewDetail(data)[0],
  };
};

export const updateInterviewFlowStatus = async (
  interviewFlowId: string,
  status: InterviewFlowStatus
) => {
  const { error } = await supabase
    .from("interview_flow")
    .update({ status: status })
    .eq("id", interviewFlowId);
  return { error: error?.message };
};

export const getInterviewFlowFromCall = async (callId: string) => {
  const { error, data } = await supabase
    .from("retell_call_interview")
    .select("interview_flow_id")
    .eq("retell_call_id", callId)
    .single();

  if (error) {
    logger(error.message, "error");
    return { error: "Something went wrong when getting interview call" };
  }
  return data.interview_flow_id;
};

export const findSharedFilenames = async (
  candidateId: string,
  interviewId: string
): Promise<string[]> => {
  const { data, error } = await supabase
    .from("interview_flow_user_document")
    .select("filename")
    .eq("candidate_id", candidateId)
    .eq("interview_flow_id", interviewId);

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }
  return data.map((d) => d.filename);
};

const formatInterviewFlowQuestionDb = (
  question: InterviewFlowQuestionDb
): InterviewFlowQuestion => ({
  id: question.id,
  interviewFlowId: question.interview_flow_id,
  question: question.question,
  possibleAnswers: question.possible_answers,
  createdAt: question.created_at,
});

export const getFilteringQuestions = async (interviewFlowId: string) => {
  const { error, data } = await supabase
    .from("interview_flow_question")
    .select("*")
    .eq("interview_flow_id", interviewFlowId)
    .not("possible_answers", "is", null);

  if (error || !data) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return data.map((question) =>
    formatInterviewFlowQuestionDb(question as InterviewFlowQuestionDb)
  );
};

type QuestionsLoaded = { questions: InterviewFlowQuestion[]; isLoaded: true };
type QuestionsLoading = { questions: undefined; isLoaded: false };
export const useFilteringQuestions = (
  interviewFlowId: string
): QuestionsLoaded | QuestionsLoading => {
  const [questions, setQuestions] = useState<InterviewFlowQuestion[]>();

  useEffect(() => {
    const get = async () => {
      setQuestions(await getFilteringQuestions(interviewFlowId));
    };
    get();
  }, [interviewFlowId]);

  if (typeof questions === "undefined") {
    return { questions: undefined, isLoaded: false };
  } else {
    return { questions, isLoaded: true };
  }
};

const formatInterviewOverview = (
  data: InterviewOverviewDb
): InterviewOverview => {
  return {
    active: data.active,
    total: data.non_archived_recruitment_count,
    totalScreened: data.total_screened,
  };
};

const getInterviewOverview = async (): Promise<InterviewOverview> => {
  const { data, error } = await supabase.rpc("get_interview_overview");

  if (error) {
    logger(error.message, "error");
  }

  return formatInterviewOverview(data[0]);
};

export const getIsInterviewOpen = async ({
  interviewFlowId,
}: {
  interviewFlowId: string;
}): Promise<{
  isInterviewOpen?: boolean;
  error?: string;
}> => {
  const { data, error } = await supabase
    .from("interview_flow")
    .select("status")
    .eq("id", interviewFlowId);

  if (error) {
    logger(error.message, "error");
    return { error: error.message };
  }

  if (!data || data.length === 0) {
    return { error: "Interview not found" };
  }

  return { isInterviewOpen: data[0].status === "open" };
};

export const findInterviewWrapped = async (
  interviewCallId: string
): Promise<string | undefined> => {
  const { data, error } = await supabase.rpc("find_interview_wrapped", {
    interview_call_id: interviewCallId,
  });

  if (error) {
    logger(error.message, "error");
  }

  return data?.[0];
};

const getInterviewCallCount = async (
  teamId: string
): Promise<number | undefined> => {
  const { data, error } = await supabase
    .from("v_retell_call_interview_output")
    .select("retell_call_id")
    .eq("team_id", teamId)
    .eq("processing_status", "success");

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return data.length;
};

type TotalCallsLoaded = { count: number; isLoaded: true };
type TotalCallsLoading = { count: undefined; isLoaded: false };
export const useTotalInterviewCallsRealtime = ():
  | TotalCallsLoaded
  | TotalCallsLoading => {
  const [count, setCount] = useState<number>();
  const { team } = useRecruiterTeam();

  const interviewCallsCountChannelName = "db-interview-calls-count";
  const isChannelHealthy = useRealtimeChannelHealth(
    interviewCallsCountChannelName,
    !!team?.id
  );

  useEffect(() => {
    // reset count on team change
    setCount(undefined);
  }, [team?.id]);

  useEffect(() => {
    // only sub if we have no count yet
    if (!team?.id || typeof count != "undefined") return;
    const channel = supabase.channel(interviewCallsCountChannelName);

    channel.on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "interview_call_output",
        filter: `team_id=eq.${team?.id}`,
      },
      async () => setCount(await getInterviewCallCount(team?.id))
    );

    channel.on(
      "postgres_changes",
      {
        event: "UPDATE",
        schema: "public",
        table: "interview_call_output",
        filter: `team_id=eq.${team?.id}`,
      },
      async () => setCount(await getInterviewCallCount(team?.id))
    );

    channel.subscribe(async (status) => {
      if (status === "SUBSCRIBED") {
        setCount(await getInterviewCallCount(team?.id));
      }
    });
  }, [team?.id, isChannelHealthy, count]);

  // we check that the value is a undefined, since 0 is falsy in javascript
  if (typeof count !== "undefined") {
    return { count, isLoaded: true };
  } else {
    return { count: undefined, isLoaded: false };
  }
};

type FlowsOverviewLoaded = InterviewOverview & { isLoaded: true };
type FlowsOverviewLoading = Partial<InterviewOverview> & { isLoaded: false };
export const useInterviewOverviewRealtime = ():
  | FlowsOverviewLoaded
  | FlowsOverviewLoading => {
  const counters = useSelector(selectInterviewsCounters);
  const { team } = useRecruiterTeam();

  const interviewFlowsCountChannelName = "db-interview-flows-count";
  const isChannelHealthy = useRealtimeChannelHealth(
    interviewFlowsCountChannelName,
    !!team?.id
  );

  const isLoaded = useRef(typeof counters != "undefined");
  useEffect(() => {
    // only sub if we have no counters yet
    if (!team?.id || (isLoaded.current && isChannelHealthy)) return;
    const channel = supabase.channel(interviewFlowsCountChannelName);

    channel.on(
      "postgres_changes",
      {
        event: "INSERT",
        schema: "public",
        table: "interview_flow",
        filter: `team_id=eq.${team?.id}`,
      },
      async () => store.dispatch(addCounters(await getInterviewOverview()))
    );

    channel.on(
      "postgres_changes",
      {
        event: "UPDATE", // we look for updates since the count should change when archiving
        schema: "public",
        table: "interview_flow",
        filter: `team_id=eq.${team?.id}`,
      },
      async () => store.dispatch(addCounters(await getInterviewOverview()))
    );

    channel.subscribe(async (status) => {
      if (status === "SUBSCRIBED") {
        store.dispatch(addCounters(await getInterviewOverview()));
      }
    });
  }, [team?.id, isChannelHealthy]);

  // we check that the value is a undefined, since 0 is falsy in javascript
  if (typeof counters !== "undefined") {
    return { ...counters, isLoaded: true };
  } else {
    return { ...(counters || {}), isLoaded: false };
  }
};

export type TeamInterviewStats = {
  teamName: string;
  email: string;
  numInterviews: number;
  hoursSinceCreatedAt: number;
};

type TeamInterviewStatsDb = {
  team_name: string;
  email: string;
  num_interviews: number;
  hours_since_created_at: number;
};

export const getTeamInterviewStats = async (): Promise<
  TeamInterviewStats[]
> => {
  const { data, error } = await supabase.rpc("get_team_interview_stats");

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return data.map((team: TeamInterviewStatsDb) => ({
    teamName: team.team_name,
    email: team.email,
    numInterviews: team.num_interviews,
    hoursSinceCreatedAt: team.hours_since_created_at,
  }));
};

export const updateRecruitingPlan = async ({
  plan,
  paymentMethod,
  hasFreeTrial = false,
}: {
  plan: RecruitPlanName;
  paymentMethod?: string;
  hasFreeTrial?: boolean;
}): Promise<{ error: string | undefined }> => {
  const res = await fetchAuthWrapper.post("/be-api/upsert-recruiting-plan", {
    plan,
    paymentMethod,
    hasFreeTrial,
  });

  if (res.status !== 200) {
    logger("Error upsert-recruiting-plan", "error");
    return { error: "Something went wrong while updating your subscription" };
  }

  return { error: undefined };
};

export type CandidateShareStatus = "team" | "link";

export const getCandidateInterviewShareStatus = async ({
  callId,
  candidateId,
}: {
  callId: string;
  candidateId: string;
}): Promise<CandidateShareStatus | null> => {
  const { data, error } = await supabase
    .from("recruiter_shared_interview_call")
    .select("*")
    .limit(1)
    .eq("interview_call_id", callId)
    .eq("candidate_id", candidateId)
    .gte("shared_until", "now()");

  if (error) {
    logger(error.message, "error");
    return null;
  }

  if (data.length === 0) {
    return "team";
  }

  return "link";
};

export const updateCandidateInterviewShareStatus = async ({
  callId,
  candidateId,
  status,
}: {
  callId: string;
  candidateId: string;
  status: CandidateShareStatus;
}): Promise<{ error: string | undefined }> => {
  if (status === "team") {
    const { error } = await supabase
      .from("recruiter_shared_interview_call")
      .delete()
      .eq("interview_call_id", callId)
      .eq("candidate_id", candidateId);

    if (error) {
      logger(error.message, "error");
      return { error: "Something went wrong while updating the share status" };
    }
  }

  if (status === "link") {
    const sharedUntil = addDays(new Date(), 180);
    const { error } = await supabase
      .from("recruiter_shared_interview_call")
      .upsert([
        {
          interview_call_id: callId,
          candidate_id: candidateId,
          shared_until: sharedUntil,
        },
      ]);

    if (error) {
      logger(error.message, "error");
      return { error: "Something went wrong while updating the share status" };
    }
  }

  return { error: undefined };
};

export const findInterviewFlowOverviewList = async ({
  teamId,
  limit,
}: {
  teamId?: string;
  limit?: number;
}): Promise<undefined | InterviewBasic[]> => {
  const { data, error } = await (limit
    ? supabase
        .from("interview_flow")
        .select("id,role_title,org_name,company_logo")
        .eq("team_id", teamId)
        .eq("interview_type", "recruitment")
        .neq("status", "archived")
        .order("created_at", { ascending: false })
        .limit(limit)
    : supabase
        .from("interview_flow")
        .select("id,role_title,org_name,company_logo")
        .eq("team_id", teamId)
        .eq("interview_type", "recruitment")
        .neq("status", "archived")
        .order("created_at", { ascending: false }));

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }
  if (!data.length) return undefined;
  return data.map((d) => ({
    id: d.id,
    roleTitle: d.role_title,
    orgName: d.org_name,
    companyLogo: d.company_logo,
  }));
};

export const useInterviewFlowOverviewList = ({
  teamId,
  limit,
}: {
  teamId?: string;
  limit?: number;
}):
  | { isLoaded: true; interviewFlowOverviewList: InterviewBasic[] }
  | { isLoaded: false; interviewFlowOverviewList: undefined } => {
  const [interviewFlowOverviewList, setInterviewFlowOverviewList] =
    useState<InterviewBasic[]>();

  useEffect(() => {
    const get = async () => {
      if (!teamId) return;
      const list = await findInterviewFlowOverviewList({ teamId, limit });
      setInterviewFlowOverviewList(list || []);
    };
    get();
  }, [teamId, limit]);

  if (!interviewFlowOverviewList) {
    return {
      isLoaded: false,
      interviewFlowOverviewList: undefined,
    };
  } else {
    return {
      isLoaded: true,
      interviewFlowOverviewList: interviewFlowOverviewList,
    };
  }
};

export const extractInterviewFlowFromText = async (
  text: string
): Promise<{ error?: string; data?: Partial<InterviewDetails> }> => {
  const res = await fetchAuthWrapper.post(
    "/be-api/extract-interview-flow-from-free-text",
    {
      text,
    }
  );

  if (res.status !== 200) {
    logger("Error extracting interview flow from free text", "error");
    return { error: "Something unexpected happened" };
  }

  const { body } = await res.json();

  return {
    data: {
      orgName: body.interview_flow_data.org_name,
      roleTitle: body.interview_flow_data.role_title,
      location: body.interview_flow_data.location,
      jobDescription: body.interview_flow_data.job_description,
      language: body.interview_flow_data.language,
      hiringManager: body.interview_flow_data.hiring_manager,
      additionalInfo: body.interview_flow_data.additional_info,
      questions: body.interview_flow_data.questions?.map((q: string) => ({
        question: q,
      })),
    },
  };
};

type dBCandidateComparison = {
  role_title: string;
  org_name: string;
  questions: any;
  ended_at: string;
  recording_url: string;
  first_name: string;
  last_name: string;
  full_name: string;
  photo_url: string;
  candidate_id: string;
  interview_flow_questions: string[];
  all_scored_candidates_list: ListedCandidate[];
};

export type CandidateComparison = Pick<
  UserInfo,
  "id" | "firstName" | "lastName" | "fullName" | "avatarUrl"
> & { questions?: Question[]; endedAt: string; recordingUrl?: string };

export type ListedCandidate = Pick<
  UserInfo,
  "id" | "fullName" | "email" | "avatarUrl"
> & { score: number };

export const getCandidateComparison = async ({
  interviewFlowId,
  candidateIds,
}: {
  interviewFlowId: string;
  candidateIds: string[];
}): Promise<{
  candidates: CandidateComparison[];
  roleTitle: string;
  orgName: string;
  interviewFlowQuestions: string[];
  allCandidatesList: ListedCandidate[];
}> => {
  const { data, error } = await supabase.rpc("get_candidate_questions_score", {
    p_interview_flow_id: interviewFlowId,
    p_candidate_ids: candidateIds,
  });
  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }
  if (!data.length) {
    logger(
      "Something went wrong when fetching the candidate comparison",
      "error"
    );
    throw new Error(
      "Something went wrong when fetching the candidate comparison"
    );
  }

  const dbCandidateComparisons: dBCandidateComparison[] = data;

  return {
    orgName: dbCandidateComparisons[0].org_name,
    roleTitle: dbCandidateComparisons[0].role_title,
    interviewFlowQuestions: dbCandidateComparisons[0].interview_flow_questions,
    allCandidatesList: dbCandidateComparisons[0].all_scored_candidates_list,
    candidates: dbCandidateComparisons
      .filter((c) => c.candidate_id)
      .map((c) => ({
        id: c.candidate_id,
        firstName: c.first_name,
        lastName: c.last_name,
        fullName: c.full_name,
        avatarUrl: c.photo_url,
        questions: c.questions,
        endedAt: c.ended_at,
        recordingUrl: c.recording_url,
      })),
  };
};

export const getTopCandidateId = async ({
  interviewFlowId,
  limit = 3,
}: {
  interviewFlowId: string;
  limit?: number;
}): Promise<string[]> => {
  const { data, error } = await supabase.rpc("get_top_candidate_by_score", {
    p_interview_flow_id: interviewFlowId,
    p_limit: limit,
  });
  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }

  return data.map((d: { candidate_id: string }) => d.candidate_id);
};

export const voteForHire = async ({
  hireVote,
  voterId,
  pipelineMemberAddressId,
  interviewFlowId,
}: {
  hireVote: HireVote;
  voterId: string;
  pipelineMemberAddressId: string;
  interviewFlowId: string;
}) => {
  const { error } = await supabase.from("candidate_hire_vote").upsert({
    address_id: pipelineMemberAddressId,
    interview_flow_id: interviewFlowId,
    created_by: voterId,
    hire_vote: hireVote,
  });

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }
};

export const removeVoteForHire = async ({
  voterId,
  pipelineMemberAddressId,
  interviewFlowId,
}: {
  voterId: string;
  pipelineMemberAddressId: string;
  interviewFlowId: string;
}) => {
  const { error } = await supabase
    .from("candidate_hire_vote")
    .delete()
    .eq("address_id", pipelineMemberAddressId)
    .eq("interview_flow_id", interviewFlowId)
    .eq("created_by", voterId);

  if (error) {
    logger(error.message, "error");
    throw new Error(error.message);
  }
};
