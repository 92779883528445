import { Headphones01Icon } from "Icons/Headphones01";
import { LinkExternal02 } from "Icons/LinkExternal02";
import { PlayIcon } from "Icons/Play";
import { StopIcon } from "Icons/Stop";
import { XCloseIcon } from "Icons/XClose";

import { useRef, useState } from "react";
import ReactPlayer from "react-player";

import { css } from "@emotion/react";

import { useDraggable } from "@dnd-kit/core";

import { Button } from "components/shared/library/Button";
import { ButtonWrapper } from "components/shared/library/ButtonWrapper";
import { LinkWrapper } from "components/shared/library/LinkWrapper";
import { Tooltip } from "components/shared/library/Tooltip";

import { ChildrenRef } from "contexts/PictureInPictureContext";

import { Transcript } from "apis/recruit";

import { purple, uiGray, white } from "constants/colors";

import { SkipBackButton } from "../interviewCandidateDetail/SkipBackButton";
import { SkipForwardButton } from "../interviewCandidateDetail/SkipForwardButton";
import { PipTrack } from "./PipTrack";

const style = {
  wrapper: css({
    display: "flex",
    flexDirection: "column",
    position: "relative",
    padding: 8,
    gap: 8,
    borderRadius: 16,
    background: purple[200],
    border: `1px solid ${purple[300]}`,
    boxShadow:
      "0px 12px 16px -4px rgba(27, 27, 27, 0.08), 0px 4px 6px -2px rgba(27, 27, 27, 0.03)",
    height: "fit-content",
  }),
  player: css({
    overflow: "hidden",
    borderRadius: 8,
    width: 240,
    height: 140,
    background: uiGray[40],
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }),
  icon: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 62,
    height: 62,
    background: uiGray[10],
    borderRadius: "50%",
  }),
  track: css({
    position: "absolute",
    bottom: 8,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  }),
  embeddedControls: css({
    position: "absolute",
    top: 8,
    left: 8,
    width: "calc(100% - 16px)",
    display: "flex",
    justifyContent: "space-between",
  }),
  controls: css({
    display: "flex",
    gap: 8,
    justifyContent: "center",
  }),
};

export type Props = {
  pipPlayerRef: React.RefObject<ReactPlayer>;
  url?: string;
  playing: boolean;
  playbackRate: number;
  showVideo?: boolean;
  togglePlay: () => void;
  skipBackButtonRef: React.RefObject<HTMLButtonElement>;
  skipFwdButtonRef: React.RefObject<HTMLButtonElement>;
  transcriptWithTimeStamp?: Transcript;
  seekToSecond: (second: number) => void;
  onClose: () => void;
  candidateInterviewPageUrl?: string;
};
export const PictureInPicturePlayer = ({
  pipPlayerRef,
  url,
  playing,
  playbackRate,
  togglePlay,
  skipBackButtonRef,
  skipFwdButtonRef,
  transcriptWithTimeStamp,
  seekToSecond,
  showVideo,
  onClose,
  candidateInterviewPageUrl,
}: Props) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: "draggable",
  });

  const [duration, setDuration] = useState(0);

  const pipTrackRef = useRef<ChildrenRef>();
  const skipFwdRef = useRef<ChildrenRef>();
  const skipBackRef = useRef<ChildrenRef>();

  return (
    <div
      css={[
        style.wrapper,
        transform
          ? css({
              transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
            })
          : undefined,
      ]}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      <div css={style.player}>
        {!showVideo && (
          <div css={style.icon}>
            <Headphones01Icon stroke={uiGray[70]} height={24} width={24} />
          </div>
        )}
        <ReactPlayer
          ref={pipPlayerRef}
          url={url}
          playing={playing}
          playbackRate={playbackRate}
          width={240}
          height={140}
          onProgress={(state: { playedSeconds: number }) => {
            skipFwdRef.current?.handleProgress(state);
            skipBackRef.current?.handleProgress(state);
            pipTrackRef.current?.handleProgress(state);
          }}
          progressInterval={10}
          onDuration={setDuration}
          style={showVideo ? undefined : { display: "none" }}
        />
        <div css={style.track}>
          <PipTrack
            ref={pipTrackRef}
            duration={duration}
            seekToSecond={seekToSecond}
          />
        </div>
        <div css={style.embeddedControls}>
          {candidateInterviewPageUrl && (
            <Tooltip content={"Go to candidate interview page"}>
              <LinkWrapper
                href={candidateInterviewPageUrl}
                ariaLabel={"Go to candidate interview page"}
                sameTab
              >
                <LinkExternal02 stroke={white} width={12} height={12} />
              </LinkWrapper>
            </Tooltip>
          )}
          <ButtonWrapper ariaLabel={"Close miniplayer"} onClick={onClose}>
            <XCloseIcon stroke={white} width={12} height={12} />
          </ButtonWrapper>
        </div>
      </div>
      <div css={style.controls}>
        <SkipBackButton
          ref={skipBackRef}
          skipBackButtonRef={skipBackButtonRef}
          transcriptWithTimeStamp={transcriptWithTimeStamp}
          seekToSecond={seekToSecond}
        />
        <Button
          onClick={togglePlay}
          IconLeft={playing ? StopIcon : PlayIcon}
          color="gray"
          size="small"
          ariaLabel={playing ? "Stop recording" : "Play recording"}
        />
        <SkipForwardButton
          ref={skipFwdRef}
          skipFwdButtonRef={skipFwdButtonRef}
          transcriptWithTimeStamp={transcriptWithTimeStamp}
          seekToSecond={seekToSecond}
        />
      </div>
    </div>
  );
};
