import { ReactNode, useRef } from "react";

import { css, SerializedStyles } from "@emotion/react";

import { trackButton } from "interfaces/tracker";
import Link from "next/link";

type Props = {
  href: string;
  sameTab?: boolean;
  stopPropagation?: boolean;
  download?: string;
  ariaLabel: string;
  testId?: string;
  children: ReactNode;
  customCss?: SerializedStyles | SerializedStyles[];
};

const style = {
  hidden: css({ display: "hidden" }),
  button: css({
    background: "none",
    border: "none",
    cursor: "pointer",
    padding: 0,
    display: "flex",
  }),
};

export const LinkWrapper = ({
  href,
  sameTab,
  stopPropagation,
  download,
  ariaLabel,
  testId,
  children,
  customCss,
}: Props) => {
  const linkRef = useRef<HTMLAnchorElement>(null);

  return (
    <div>
      {download ? (
        // we should not use nextJs Link component for non-navigation purposes
        <a ref={linkRef} href={href} download={download} css={style.hidden} />
      ) : (
        <Link
          ref={linkRef}
          target={sameTab ? "_self" : "_blank"}
          href={href}
          css={style.hidden}
          onClick={(e) => {
            if (stopPropagation) e.stopPropagation();
          }}
        />
      )}
      <button
        aria-label={ariaLabel}
        css={[style.button, customCss]}
        onClick={(e) => {
          if (stopPropagation) e.stopPropagation();
          // if cmd key is pressed, the link should open in a new tab
          if (e.metaKey) linkRef.current?.setAttribute("target", "_blank");

          linkRef?.current?.click();

          trackButton(ariaLabel, window.location.pathname, undefined);
          // reset target behaviour to default
          linkRef.current?.setAttribute("target", sameTab ? "_self" : "_blank");
        }}
        data-testid={testId}
      >
        {children}
      </button>
    </div>
  );
};
