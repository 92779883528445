import React, { useContext, useEffect, useRef, useState } from "react";
import ReactPlayer, { ReactPlayerProps } from "react-player";

import { useRouter } from "next/router";

import { PipOverlay } from "components/recruit/shared/PipOverlay";

import { Transcript } from "apis/recruit";

import { getSignInUrl, isRecruit } from "utils/urls";

const PictureInPictureContext = React.createContext<Context | undefined>(
  undefined
);

type Context = {
  startPipMode: () => void;
  seekToSecond: (second: number) => void;
  toggleIsPlaying: () => void;
  isPlaying: boolean;
  staticPlayerProps: ReactPlayerProps;
  updateVideoDetails: (props: VideoDetails) => void;
  playbackRate: number;
  setPlaybackRate: (playbackRate: number) => void;
  trackRef: React.MutableRefObject<ChildrenRef | undefined>;
  timeRef: React.MutableRefObject<ChildrenRef | undefined>;
  transcriptPlaybackRef: React.MutableRefObject<ChildrenRef | undefined>;
  duration: number;
  disableControls: boolean;
  skipBack: () => void;
  skipForward: () => void;
};

type Props = {
  children: React.ReactNode;
};

export type ChildrenRef = {
  handleProgress: (state: { playedSeconds: number }) => void;
};

type VideoDetails = {
  transcriptWithTimeStamp?: Transcript;
  showVideo: boolean;
  recordingUrl?: string;
  candidateInterviewPageUrl: string;
};

export const PictureInPictureProvider = ({ children }: Props) => {
  const router = useRouter();
  const staticPlayerRef = useRef<ReactPlayer>(null);
  const pipPlayerRef = useRef<ReactPlayer>(null);
  const skipBackButtonRef = useRef<HTMLButtonElement>(null);
  const skipFwdButtonRef = useRef<HTMLButtonElement>(null);

  const [isPipPlaying, setIsPipPlaying] = useState(false);
  const [isStaticPlaying, setIsStaticPlaying] = useState(false);
  const [isPipMode, setIsPipMode] = useState(false);
  const [pipVideoDetails, setPipVideoDetails] = useState<VideoDetails>();
  const [staticVideoDetails, setStaticVideoDetails] = useState<VideoDetails>();

  const [isNewPage, setIsNewPage] = useState(true);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [staticDuration, setStaticDuration] = useState(0);

  const trackRef = useRef<ChildrenRef>();
  const timeRef = useRef<ChildrenRef>();
  const transcriptPlaybackRef = useRef<ChildrenRef>();
  const [hasPlayed, setHasPlayed] = useState(false);

  useEffect(() => {
    if (isPipPlaying) setHasPlayed(true);
  }, [isPipPlaying]);

  // each time we change page we reset isNewPage to true
  const prevPath = useRef(router.asPath);
  useEffect(() => {
    if (router.asPath !== prevPath.current) {
      setIsNewPage(true);
      prevPath.current = router.asPath;
      // pip does not follow when exiting the page if it has not been interacted with
      if (!hasPlayed) setIsPipMode(false);
      // pip does not follow when exiting the page if out of recruit, or signing out
      if (
        !isRecruit(router.asPath) ||
        router.asPath == getSignInUrl({ currentPath: router.asPath })
      )
        setIsPipMode(false);
    }
  }, [router.asPath, hasPlayed]);

  // when on a new page, the static player should be paused and at the beginning
  useEffect(() => {
    if (isNewPage) {
      setIsStaticPlaying(false);
      staticPlayerRef.current?.seekTo(0, "seconds");
      if (!isPipMode) {
        // if not currently in pip mode, also reset pip player
        setIsPipPlaying(false);
        pipPlayerRef.current?.seekTo(0, "seconds");
      }
    }
  }, [isNewPage, isPipMode]);

  return (
    <PictureInPictureContext.Provider
      value={{
        startPipMode: () => setIsPipMode(true),
        seekToSecond: (second: number) => {
          staticPlayerRef.current?.seekTo(second, "seconds");
          pipPlayerRef.current?.seekTo(second, "seconds");
        },
        toggleIsPlaying: () => {
          setPipVideoDetails(staticVideoDetails);
          if (isNewPage) {
            // if toggling play on the first time on a new page reset the pip player
            pipPlayerRef.current?.seekTo(0, "seconds");
            setIsNewPage(false);
          }
          setIsPipPlaying(!isStaticPlaying);
          setIsStaticPlaying(!isStaticPlaying);
        },
        isPlaying: isStaticPlaying,
        staticPlayerProps: {
          ref: staticPlayerRef,
          playing: isStaticPlaying,
          onDuration: setStaticDuration,
          onProgress: (state: { playedSeconds: number }) => {
            // for all these children components we need to keep track of the current time at any given time.
            // putting the current time in a state here and passing that state would trigger re-rendering in all
            // the context consumers every time the time changes, this is a lot of rerenders.
            // instead we create ref components that handle the current time in a state of their own,
            // onProgress, we trigger these components handleProgress method
            trackRef.current?.handleProgress(state);
            timeRef.current?.handleProgress(state);
            transcriptPlaybackRef.current?.handleProgress(state);
          },
          progressInterval: 10,
          playbackRate: playbackRate,
          volume: 0,
        },
        updateVideoDetails: (props: VideoDetails) => {
          setStaticVideoDetails(props);
          if (!isPipMode) setPipVideoDetails(props);
        },
        playbackRate,
        setPlaybackRate,
        trackRef,
        timeRef,
        transcriptPlaybackRef,
        duration: staticDuration,
        disableControls: isNewPage,
        skipBack: () => skipBackButtonRef.current?.click(),
        skipForward: () => skipFwdButtonRef.current?.click(),
      }}
    >
      {children}
      <PipOverlay
        isPipMode={isPipMode}
        pipPlayerRef={pipPlayerRef}
        url={pipVideoDetails?.recordingUrl}
        showVideo={pipVideoDetails?.showVideo}
        playing={isPipPlaying}
        playbackRate={playbackRate}
        togglePlay={() => {
          setIsPipPlaying(!isPipPlaying);
          if (!isNewPage) setIsStaticPlaying(!isPipPlaying);
        }}
        skipBackButtonRef={skipBackButtonRef}
        skipFwdButtonRef={skipFwdButtonRef}
        transcriptWithTimeStamp={pipVideoDetails?.transcriptWithTimeStamp}
        seekToSecond={(second: number) => {
          pipPlayerRef.current?.seekTo(second, "seconds");
          if (!isNewPage) staticPlayerRef.current?.seekTo(second, "seconds");
        }}
        onClose={() => setIsPipMode(false)}
        candidateInterviewPageUrl={pipVideoDetails?.candidateInterviewPageUrl}
      />
    </PictureInPictureContext.Provider>
  );
};

export default PictureInPictureContext;

export const usePictureInPicture = () => {
  const context = useContext(PictureInPictureContext);
  if (!context) {
    throw new Error(
      "usePictureInPicture must be used within a PictureInPictureProvider"
    );
  }
  return context;
};
